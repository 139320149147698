@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    /* font-family: -apple-system,BlinkMacSystemFont,Segoe UI, Roboto , Helvetica Neue , Ubuntu; */
    /* font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; */
    font-family: 'Inter';
  }
}

